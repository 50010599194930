.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.outerparent{
  position: relative;
  display: flex;
  flex-direction: column;
}
.parent {
  display: flex;
  flex-direction: column;
  top:0vh;
  position: relative;
  
}

@media (max-width: 584px){
  .box {
    width: 100vw !important;
  }
  #navigation-bar{
    width: 100vw !important;
  }
  
}
.box {
  position: relative;
  background-color: white;
  width: 98.93vw;
}

#navigation-bar{
  height: 20vh;
  width: 98.93vw;
  position: relative;
  z-index: 200;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2)
}

@media (max-width: 584px){
  #about {
    height: 130vh !important;
  }
}


#about{
  background-color: white;
  height: 100vh;
}

/* @media (max-width: 584px){
  #service {
    height: 210vh !important;
  }
} */

@media (max-width: 584px){
  #team {
    height: 155vh !important;
  }
}
#services{
  background-color: white;
}


#home{
  position: relative;
  margin-top:-20vh;
  height: 100vh;
}



