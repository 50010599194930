.aboutParentTop{
    display: flex;
    flex-direction: row;
    color: blue;
    position: relative;
    z-index: 10;
    height: 90%;
    width: 100%;
    

}

@media (max-width: 584px){
    .aboutParentTop {
      height: 100% !important;
    }
  }
@media (max-width: 584px){
    .aboutParentHeader {
      height: 5% !important;
    }
  }

  @media (max-width: 584px){
    .statsParent {
      flex-direction: column !important;
    }
  }

.aboutParentHeader{
    height: 10%;
    display: flex;
    flex-direction: row;
}
.aboutParentTop1{
    display: flex;
    flex-direction: column;
    color: blue;
    position: relative;
    z-index: 10;
    height: 100%;
    width: 100%;
    

}

.leftBorde{
    width: 1%;
    background-color: rgba(29,49,85,255);
}

.aboutParent{
    width: 93%;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.emptyVerticalSpaceParent{
    width:5%;
    background-color: white;
    display: flex;
    flex-direction: column;

}

.emptyVertcalWhiteSpace1{
    height:100%;
    background-color: white;
}

.emptyVertcalGreySpace1{
    height:10%;
    background-color: rgb(238,238,240,255);
}
.emptyHorizontalSpaceTop{
    width: 98%;
    background-color: white;

}
.emptyHorizontalSpaceMid{
    height: 15%;
    background-color: white;

}
.blueLine{
    height: 0.5%;
    background-color: rgba(29,49,85,255);
    width: 20%;
    
}
.about{
    height: 99.5%;
    color: rgba(29,49,85,255);


}
.greyFooter{

    height: 10%;
    background-color: rgba(238,238,240,255);
}
.statsParent{
    width: 98%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.emptyHorizontalSpaceStatsTop{
    height: 10%;
    background-color: white;
}

.numberStyle{
    font-size: 70px;
    margin: 0px;
    color: rgba(167,173,175,255);
    top: 10%;
    position: relative;
}
.textStyle{
    font-size: 25px;
    margin: 0px;
    color: rgba(167,173,175,255);
    top: 10%;
    position: relative;
}
.statsBox1{
    height: 100%;
    text-align: center;
    position: relative;
}
.emptyHorizontalSpaceStatsMid{
    height: 2%;
    background-color: white;
}
.statsBox2{
    height: 100%;
    text-align: center;
    position: relative;
}

@media (max-width: 584px){
    .emptyParent {
      display: none !important;
    }
  }
.emptyParent{
    width: 15%;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.rightBorde{
    width: 1%;
    background-color: rgba(29,49,85,255)
}

