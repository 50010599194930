.serviceParentTop{
    display: flex;
    flex-direction: row;
    color: blue;
    position: relative;
    z-index: 10;
    height: 100%;
    width: 100%;
    

}

.leftBorder{
    width: 1%;
    background-color: rgba(29,49,85,255);
}

.serviceParent{
    width: 98%;
    background-color: white;
    display: flex;
    flex-direction: column;
}


.header{
    height: 100px;
    background-color: rgba(238,238,240,255);
    display: flex;
    flex-direction: row;
    

}

.headerTitle{
    width: 100%;
    background-color: rgba(238,238,240,255);
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    font-size: xx-large;
    font-weight: bold;
    color: rgba(29,49,85,255);
}

.blueBox{
    min-width: 200px;
    width: 25%;
    background-color: rgba(31,103,138,255);
}

@media (max-width: 584px){
    .greyBox {
      display: none !important;
    }
  }



.greyBox{
    width: 15%;
    background-color: rgba(238,238,240,255);
}

.emptyRowWhite{
    height: 50px;
    background-color: white;

}

.emptyRowWhite1{
    height: 1%;
    background-color: white;

}

@media (max-width: 800px){
    .imageHolder{
        grid-template-columns: auto !important;
    }
  }

.imageHolder{
    /* height: 65%; */
    color: rgba(29,49,85,255);
    display: grid;
    flex-direction: row;
    justify-content: center;
    grid-template-columns: auto auto;
    grid-template-rows: 400px 400px;
    grid-row-gap: 50px;
    grid-column-gap: 50px;
    margin: 1px;
}



.image{
    width: 20%;
}


.whiteFooter{

    height: 9%;
    background-color: white;
}


.rightBorder{
    width: 1%;
    background-color: rgba(29,49,85,255)
}

